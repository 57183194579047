.faq-landing {
    position: relative;
    min-height: 55vh;
    padding-top: 250px;
    padding-bottom: 30px;
}
.faq-img-container {
    position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
    z-index: 1;
}
.faq-img-container img {
	object-fit: cover;
    object-position: bottom;
	display: inline-block;
	height: 100%;
	width: 100%;
	vertical-align: middle;
	filter: brightness(70%); 
}
.faq-headline {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.faq-headline h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color-white);
    margin: 10px;
}
.faq-headline p {
    font-size: 1.3rem;
    font-weight: 00;
    color: var(--color-white);
    margin: 10px;
}
.faq-content {
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: min(1100px, 90%);
    margin-left: auto;
    margin-right: auto;
}
.faq-content h2 {
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
}
.faq {
    width: 100%;
    margin: 15px 0;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--color-black);
}
.question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.question h3 {
    font-size: 1.5rem;
    margin: 10px 0;
}
.question img {
    transition: .5s ease-in-out;
}
.answer {
    margin: 10px 0;
    font-size: 1.1rem;
    line-height: 1.6;
}
.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
}
.faq.active .answer {
    max-height: 1000px;
    animation: fade .5s ease-in-out;
}
.faq.active img {
    transform: rotate(-90deg);
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}