.contact-landing {
    position: relative;
    min-height: 55vh;
    padding-top: 250px;
    padding-bottom: 30px;
}
.page-img-container {
    position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
    z-index: 1;
}
.page-img-container img {
	object-fit: cover;
    object-position: center;
	display: inline-block;
	height: 100%;
	width: 100%;
	vertical-align: middle;
	filter: brightness(70%); 
}
.contact-headline {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact-headline h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--color-white);
    margin: 10px;
}
.contact-headline p {
    font-size: 1.3rem;
    font-weight: 00;
    color: var(--color-white);
    margin: 10px;
}