.slide-landing {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 700px;
    z-index: 5;
    overflow: hidden;
    background-color: var(--color-black-shade);
}
.slide-landing h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.2rem;
    font-weight: 600;
    color: var(--color-white);
    z-index: 50;
    margin: 0;
    text-align: center;
}
.slide-landing img {
    width: 100%;
    min-height: 700px;
    object-fit: cover;
    filter: grayscale(10%) brightness(60%);
    transform: scale(1.5);
}
.slide-center {
    overflow: hidden;
}
.slide-tracker {
    overflow: hidden;
    position: relative;
}
.slide {
    position: absolute;
    opacity: 0;
    animation: fade 10s infinite ease-in;
}
.slide:nth-child(1) {
    animation-delay: 0s;
}
.slide:nth-child(2) {
    animation-delay: 3s;
}
.slide:nth-child(3) {
    animation-delay: 6s;
}
.slide:nth-child(4) {
    animation-delay: 1s;
}
.slide:nth-child(5) {
    animation-delay: 4s;
}
.slide:nth-child(6) {
    animation-delay: 8s;
}

@keyframes fade {
    0% { opacity: 0; }
    10% { opacity: 0; }
    30% { opacity: 1; }
    40% { opacity: 1; }
    50% { opacity: 1; }
    60% { opacity: 1; }
    100% { opacity: 0; }
}

@media (max-width: 1000px) {
    .slide-landing {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        height: 1000px;
    }
    .slide-tracker {
        z-index: 5;
    }
    .slide-center img {
        position: absolute;
        top: 200px;
        z-index: 1;
    }
}