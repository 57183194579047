header {
	width: 100%;
	position: absolute;
	top: 0;
	bottom: auto;
	left: 0;
	right: 0;
	z-index: 1000;
}

.header-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.info-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background: var(--color-blue);
	width: 100%;
	min-height: 60px;
	gap: 0;
}
.info {
	padding: 10px 30px;
	display: flex;
	gap: 15px;
	font-size: 1.1rem;
	color: var(--color-white);
}
.info img {
	filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
}
.info span {
	transition: 0.2s;
}
.info span:hover {
	color: var(--color-orange);
	cursor: pointer;
	scale: 1.02;
}

.navigation-container {
	/* background-color: var(--color-white); */
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: min(1500px, 100%);
	position: relative;
	z-index: 1;
	padding: 10px 5px;
}
/* .img-container {
	flex: 1;
	display: flex;
	justify-content: center;
}
.img-container img {
	height: 90px;
	width: 270px;
	object-fit: cover;
	
} */
.name-container {
	flex: 1;
	text-align: center;
}
.name-container > span {
	font-size: 1.3rem;
	font-weight: 700;
	color: var(--color-white);
	padding: 10px 15px;
	background-color: var(--color-blue);
	text-align: center;
}
.horizontal-view {
	flex: 2;
}
.horizontal-view ul {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.horizontal-view li {
	padding: 15px 5px;
	text-align: center;
}
.horizontal-view li a {
	text-decoration: none;
	color: var(--color-white);
	font-size: 1.2rem;
	font-weight: 400;
	transition: .2s ease-out;
}
.horizontal-view li a:hover {
	color: var(--color-orange);
	text-decoration: underline var(--color-orange) 3px;
	text-underline-offset: 10px;
}
.btn-container {
	flex: 1;
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 3;
}
.btn-container button {
	width: 230px;
	padding: 10px;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--color-white);
	border-radius: 25px;
	border: 2px solid var(--color-white);
	background: transparent;
	cursor: pointer;
	transition: .2s ease-in-out;
}
.btn-container button:hover {
	background-color: var(--color-blue);
	border: 2px solid var(--color-blue);
	color: var(--color-white);
}

.vertical-view {
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 300px;
	background-color: rgba(7, 45, 69, 0.7);
	backdrop-filter: blur(10px);
	box-shadow: -10px 0 10px rgba(0, 0, 0, .1);
	z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.vertical-view ul {
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}
.vertical-view li {
	padding: 20px;
	width: 100%;
}
.vertical-view li img {
	cursor: pointer;
}
.vertical-view li a {
	font-size: 1.2rem;
	font-weight: 400;
	text-decoration: none;
	color: var(--color-white);
	transition: .2s ease-in-out;
	cursor: pointer;
}
.vertical-view li:hover {
	background-color: rgba(111, 111, 111, .2);
}
.vertical-view .btn-container {
	display: block;
}

.menu-btn {
	display: none;
}
.vertical-view {
	display: none;
}

@media (max-width: 1100px) {
	.menu-btn {
		display: block;
		cursor: pointer;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin: 15px;
		flex: 1;
	}
	.name-container {
		margin: 15px;
		display: flex;
		justify-content: flex-start;
	}
	.horizontal-view {
		display: none;
	}
	.btn-container {
		display: none;
	}
	.img-container {
		flex: 1;
		justify-content: flex-start;
	}
}
@media (max-width: 600px) {
	.info-container {
		justify-content: flex-start;
	}
	.img-container img {
		width: 200px;
	}
	.name-container {
		flex: 2;
	}
}