/* Home Landing Section */

.home-landing {
	overflow: hidden;
	position: relative;
	padding-top: 300px;
	padding-bottom: 80px;
	min-height: 100vh;
}
.landing-img {
	position: absolute;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}
.landing-img img {
	object-fit: cover;
	object-position: right;
	display: inline-block;
	height: 100%;
	width: 100%;
	vertical-align: middle;
	filter: brightness(80%); 
}
.landing-headline {
	display: flex;
	flex-direction: column;
	width: min(1400px, 100%);
	margin: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0 15px;
	position: relative;
	justify-content: flex-end;
}
.headline-container {
	max-width: 800px;
	display: flex;
	flex-direction: column;
}
.headline-container h1 {
	font-size: 3.2rem;
	font-weight: 800;
	color: var(--color-white);
	margin: 15px;
}
.headline-container p {
	font-size: 1.2rem;
	font-weight: 400;
	color: var(--color-white);
	margin: 15px;
	max-width: 500px;
}
.headline-container button {
	width: 270px;
	padding: 15px;
	margin: 30px 15px;
	font-size: 1.2rem;
	font-weight: 900;
	color: var(--color-white);
	border-radius: 25px;
	border: 2px solid var(--color-orange);
	background: var(--color-orange);
	cursor: pointer;
	transition: .2s ease-in-out;
}
.headline-container button:hover {
	background-color: var(--color-white);
	color: var(--color-orange);
}
@media (max-width: 1100px) {
	.landing-headline {
		align-items: center;
	}
	.headline-container {
		align-items: center;
	}
	.headline-container h1,
	.headline-container p {
		text-align: center;
	}
}

/* Selling Landing Section */

.selling-landing {
	background-color: var(--color-background);
	padding: 110px 0;
	display: flex;
	justify-content: center;
}
.content-container {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	align-items: center;
	width: min(1200px, 90%);
	gap: 50px;

}
.selling-img {
	width: min(430px, 100%);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.selling-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.img-desc-container {
	background-color: var(--color-blue);
	color: var(--color-white);
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
	position: relative;
}
.img-desc-container > p {
	margin: 10px;
}
.selling-detail {
	flex: 1;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
	margin: 25px;
	padding-top: 10px;
	padding-bottom: 10px;
}
.selling-detail h2 {
	font-weight: 600;
	font-size: 2.5rem;
	margin: 0;
	width: min(600px, 100%);
}
.selling-detail > p {
	font-weight: 300;
	font-size: 1.2rem;
	color: var(--color-black-shade);
	margin: 15px 0;
}
.feature {
	display: flex;
	gap: 20px;
	align-items: center;
}

.feature > p {
	font-size: 1.3rem;
	font-weight: 500;
	color: var(--color-blue);
}
@media (max-width: 800px) {
	.content-container {
		flex-direction: column;
	}
	.selling-img {
		order: 2;
	}
	.selling-detail {
		order: 1;
	}
}

/* Selling Point */
.selling-point {
	background-color: var(--color-blue);
	padding: 50px 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.selling-point > h1 {
	font-size: 2.2rem;
	font-weight: 700;
	color: var(--color-white);
	text-align: center;
	width: min(1200px, 100%);
}
.selling-point > h1 > span {
	color: var(--color-orange);
}

/* Google Review Container */
.google-review-container {
	background-color: var(--color-white);
	padding-top: 70px;
	padding-bottom: 70px;
}