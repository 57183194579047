.contact-section {
    width: 100%;
    background-color: var(--color-background);
    padding-top: 130px;
    padding-bottom: 130px;
    display: flex;
    justify-content: center;
}
.contact-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min(1100px, 95%);
    gap: 70px;
}
.contact-content {
    flex: 1;
    padding: 5px;
}
.contact-content h2 {
    font-size: 2.2rem;
    font-weight: 600;
    margin: 0;
}
.contact-content p {
    font-size: 1.1rem;
    max-width: 600px;
    margin: 30px 0;
    color: var(--color-black-shade);
}
.contact-content img {
    height: min(250px, auto);
    width: 100%;
    object-fit: cover;
}
.form-container {
    flex: 1;
    padding: 25px;
    background-color: var(--color-white);
    border-radius: 10px;
}
form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.full-name {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.contact-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
form input,
form textarea {
    padding: 15px 10px;
    font-size: 1rem;
    border: .5px solid var(--color-black-shade);
    border-radius: 10px;
    width: 100%;
}
form textarea {
    width: 100%;
    min-height: 150px;
}
form button {
    border-radius: 25px;
    background: var(--color-orange);
    padding: 15px;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color-white);
    border: 1px solid var(--color-orange);
    cursor: pointer;
    transition: .2s ease;
}
form button:hover {
    background: transparent;
    color: var(--color-orange);
}

@media (max-width: 1200px) {
    .contact-wrapper {
        flex-direction: column;
    }
}