.footer-section {
    background-color: var(--color-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}
.sale-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: min(1000px, 90%);
    flex-wrap: wrap;
}
.slogan-container {
    flex: 2;
    padding: 10px;
}
.slogan-container h2 {
    text-align: center;
    font-size: 2.2rem;
    color: var(--color-white);
    max-width: 800px;
}
.slogan-container h2 > span {
    color: var(--color-orange);
}
.book-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}
.book-container button {
    width: 270px;
	padding: 15px;
	margin: 30px 15px;
	font-size: 1.2rem;
	font-weight: 900;
	color: var(--color-white);
	border-radius: 25px;
	border: 2px solid var(--color-orange);
	background: var(--color-orange);
	cursor: pointer;
	transition: .2s ease-in-out;
}
.book-container button:hover {
    background-color: var(--color-white);
	color: var(--color-orange);
}
.contact-row {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: min(450px, 100%);
}
.info-foot {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 1.1rem;
    color: var(--color-white);
}
.info-foot img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
    height: 24px;
    width: 24px;
}
.info-foot span {
	transition: 0.2s;
}
.info-foot span:hover {
	color: var(--color-orange);
	cursor: pointer;
	scale: 1.02;
}
.license-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: min(1200px, 90%);
}
.license-row p {
    color: var(--color-white);
    font-size: 1.1rem;
}
.license-row a {
    text-decoration: none;
    color: var(--color-white);
    transition: .2s ease;
}
.license-row a:hover {
    transform: scale(1.02);
    color: var(--color-orange);
}

@media (max-width: 600px) {
    .license-row {
        flex-direction: column;
        text-align: center;
    }
}
@media (max-width: 400px) {
    .info-foot {
        font-size: .9rem;
    }
}