body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  background-color: var(--color-white);
}
* {
  box-sizing: border-box;
}

:root {
  --color-orange: #E05C3E;
  --color-white: #E4EEED;
  --color-black: #141414;
  --color-black-shade: #5f5f5f;
  --color-blue: #085771;
  --color-blue-shade: #021E26;
  --color-background: #fcfeed;
}